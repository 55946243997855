<template>
  <tr>
    <wi-list-data-table-actions :config="config" :props="props"></wi-list-data-table-actions>
    <td class="justify-left">
      {{ props.item.id }}
    </td>
    <td class="justify-left">
      <strong>{{ props.item.name }}</strong>
    </td>
    <td class="justify-left">
      <strong>
        {{ props.item.amount_sale | money }}
      </strong>
    </td>
  </tr>
</template>
<script>
  import WiListDataTableActions from '@/default/component/WiList/WiListDataTableActions'
  export default {
    name: 'InternetPlans',
    props: {
      config: Object,
      props: Object
    },
    components: {
      WiListDataTableActions
    }
  }
</script>
<style>
td {
  padding: 0 5px !important;
}
th {
  padding: 0 5px !important;
}
</style>